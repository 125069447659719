.inner-container{
	justify-content: center;
	align-items: center;
}

.about-container{
	width: 85%;
	justify-content: center;
	flex-wrap: wrap;
	padding-top: 5vh;
}

.about-description{
	flex-direction: column;
	/*flex: 1;*/
	min-width: 500px;
	max-width: 600px;
}

.about-title{
	font-family: "Raleway", "sans-serif";
  font-weight: normal;
  color: #012641;
  margin: 0;
}

.about-paragraph{
	font-family: "Muli", "sans-serif";
  font-weight: normal;
  color: #012641;
}

.me-pic{
	object-fit: contain;
	/*width: 25vw;*/
}

.image-container{
	min-width: 500px;
	max-width: 600px;
}

.underline{
	border-top: 6px solid #60B3D1;
  padding-bottom: 10px;
}

.email{
	/*color: #ef7b45;*/
	color: #60B3D1;
}

.resume-button{
	color: #60B3D1;
}