.projects-inner-container{
	/*justify-content: center;
	align-items: center;*/
	flex-direction: column;
	flex: 1;
}

.projects-container{
	width: 100%;
}

.tiles{
	width: 100%;
	align-items: center;
	padding-bottom: 1em;
	grid-gap: 4em !important; /* work around overwrite the grid spacing for projects */
}

.projects-title{
	font-family: "Raleway", "sans-serif";
  font-weight: normal;
  margin: 2em 0 0 0;
  /*color: rgb(244, 251, 253);*/
  color: #012641;
  padding: 0 0 0 5%;
}