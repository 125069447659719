@font-face {
  font-family: 'Lato';
  src: url(../fonts/Lato.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  src: url(../fonts/Muli.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  src: url(../fonts/Raleway.ttf) format('truetype');
}

.small {
  margin: 0 0 15px 4px;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: "Lato", "sans-serif";
  font-weight: normal;
  color: rgb(244, 251, 253);
  /*opacity: 0;*/
  -moz-animation: 0.5s fade 0.5s forwards;
  animation: 0.5s fade 0.5s forwards;
}
.github-text{
  margin: 0 0 15px 4px;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: "Lato", "sans-serif";
  font-weight: normal;
  color: rgb(244, 251, 253);
  /*opacity: 0;*/
  -moz-animation: 0.5s fade 0.5s forwards;
  animation: 0.5s fade 0.5s forwards;
  cursor: pointer;
}

.big {
  font-size: 80px;
  line-height: 1.1;
  margin: 0;
  font-family: "Raleway", "sans-serif";
  /*color: rgb(244, 251, 253);*/
  color: #60B3D1;
  /*opacity: 0;*/
  -moz-animation: 0.5s fade 0.5s forwards;
  animation: 0.5s fade 0.7s forwards;
}

.medium {
  font-size: 35px;
  line-height: 1.1;
  font-family: "Muli", "sans-serif";
  font-weight: normal;
  /*opacity: 0;*/
  -moz-animation: 0.5s fade 0.5s forwards;
  animation: 0.5s fade 0.9s forwards;
  /*color: rgb(150, 218, 173);*/
  color: #ffe758;
}

.sub-container{
  flex-direction: column;
  padding-left: 15%;
  width: 100%;
}

.landing-description{
  flex-direction: row;
  /*flex: 1;*/
  width: 85%;
  /*height: 100%;*/
  /*justify-content: center;*/
  /*padding-left: 20%;*/
  /*padding-right: 15%;*/
}

.particles{
  /*right: 0;*/
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  position: absolute;
}