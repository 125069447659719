.western-logo{
	padding-top: 9px; /* round about way of centering png image */
}

.timeline{ /* work around overwrite the timeline library style */
	max-width: 80vw !important;
	padding: 2em 0 5em 0 !important;
}

.experience-title{
	text-align: center;
	font-family: "Raleway", "sans-serif";
  font-weight: normal;
  color: #F4FBFD;
  margin: 2em 0 2em 0;
}

.timeline-title{
	margin: 0;
	font-family: "Muli", "sans-serif";
  font-weight: normal;
  color: #012641;
}

.timeline-subtitle{
	margin: 0;
	font-family: "Muli", "sans-serif";
  font-weight: normal;
  color: #012641;
}

.timeline-description{
	font-family: "Muli", "sans-serif";
  font-weight: normal;
  color: #012641;
}

.date-font{
	font-family: "Muli", "sans-serif";
  font-weight: normal;
  color: #F4FBFD;
}