/*body {
  background-color: #282c34;
}*/

/*.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}

.App header {
  background-color: #181717;
  height: 10vh;
  min-height: 50px;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}

main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}*/

.form {
  height: 10vh;
  position: absolute;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
}

.form button {
  width: 20%;
  background-color: rgb(244, 251, 253);
}


.input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: #60B3D1;
  font-family: "Raleway", "sans-serif";
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
}

.button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

.send-button{
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}


/*ul, li {
  text-align: left;
  list-style: none;
}*/

.content-p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  /*text-align: center;*/
}

.message {
  display: flex;
  align-items: center;
  word-break: break-all;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: rgb(244, 251, 253);
  /*background: #0b93f6;*/
  background: #60B3D1;
  align-self: flex-end;
  font-family: "Raleway", "sans-serif";
}
.received p {
  background: #e5e5ea;
  color: black;
  font-family: "Raleway", "sans-serif";
}

.img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.inner-container{
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #012641;
}

.chat-area{
  flex-direction: column;
  width: 85vw;
  height: 70vh;
  /*position: relative;*/

  padding: 10px;
  /*height: 50vh;*/
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  /*flex-direction: column;*/
}

.chat-area::-webkit-scrollbar {
  width: 0.25rem;
}

.chat-area::-webkit-scrollbar-track {
  /*background: #1e1e24;*/
  background: rgb(244, 251, 253);
}

.chat-area::-webkit-scrollbar-thumb {
  /*background: #6649b8;*/
  background: #60B3D1;
}

.user-list {
  flex-direction: column;
  width: 10vw;
  height: 50vh;

  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.chat-title{
  text-align: center;
  font-family: "Raleway", "sans-serif";
  font-weight: normal;
  color: #F4FBFD;
  margin: 0 0 1em 0;
}